<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : !disabled ? '修改' : '查看'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    class="missionPart"
  >
    <el-form
      ref="dataForm"
      :model="dataForm"
      :rules="dataRule"
      label-width="120px"
      @keyup.enter.native="dataFormSubmit()"
    >
      <div class="tablePart">
        <el-form-item label="智能取餐柜名字" prop="freezerIds" class="fullList">
          <el-select
            v-model="dataForm.freezerIds"
            :disabled="disabled"
            clearable
            multiple
            filterable
          >
            <el-option
              v-for="(item, index) in freezerList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="轮播图片" prop="bannerId">
          <!--          <el-banner v-model="dataForm.bannerId" :disabled="disabled" />-->
          <el-button
            @click="openDialog"
            size="mini"
            type="primary"
            :disabled="disabled"
            >选择图片/视频</el-button
          >
        </el-form-item>
      </div>
      <el-table :data="dataForm.fileList" border style="width: 100%">
        <el-table-column
          prop="name"
          header-align="center"
          align="center"
          label="轮播图片/视频名字"
        >
          <template slot-scope="scope">
            <el-input
              :disabled="disabled"
              v-model="scope.row.name"
              placeholder="请输入轮播图片/视频名字"
            />
          </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" label="预览图">
          <template slot-scope="scope">
            <el-image
              style="width: 50px; height: 50px"
              :src="scope.row.imageUrl"
              v-if="scope.row.mediaType === 0"
              fit="cover"
              @click="openImg(scope.row.imageUrl)"
            />
            <video
              controls
              style="width: 50px; height: 50px"
              v-if="scope.row.mediaType === 1"
              :src="scope.row.imageUrl"
            ></video>
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          label="操作"
          v-if="!disabled"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="deleteHandle(scope.row.id, scope.row.name)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button v-if="!disabled" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
    </span>
    <!--  -->
    <img-dialog v-if="picVisible" ref="picdialog" @success="getInsidePic" />
  </el-dialog>
</template>

<script>
import { getFreezerList } from '@/utils/options.js';
import imgDialog from '@/components/el-banner';
import { keys, pick } from 'lodash';
export default {
  data() {
    return {
      disabled: false,
      visible: false,
      picVisible: false,
      freezerList: [],
      autoCropWidth: '270',
      autoCropHeight: '465',
      file: '',
      fileName: '',
      cropperVisible: false,
      files: [],
      dataForm: {
        id: '',
        freezerIds: [],
        bannerId: '',
        fileList: [], // 图片列表汇总
      },
      dataRule: {
        freezerIds: [
          {
            required: true,
            message: '智能取餐柜不能为空',
            trigger: 'blur',
          },
        ],
        imageUrl: [
          {
            required: true,
            message: '轮播图片不能为空',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  created() {},
  components: {
    imgDialog,
  },
  methods: {
    init(id, disabled) {
      this.disabled = disabled;
      this.dataForm.id = id || '';
      this.visible = true;
      this.dataForm.fileList = [];
      this.getFreezerList();
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        if (this.dataForm.id) {
          this.$http({
            url: `/cc/freezer-banner/id/query/${this.dataForm.id}`,
            method: 'get',
          }).then(({ data }) => {
            if (data && data.status === 0) {
              this.dataForm = pick(data.data, keys(this.dataForm));
              this.dataForm.freezerIds = [data.data.freezerIds];
            }
          });
        }
      });
    },
    changeUpload(file) {
      var img = file.name.substring(file.name.lastIndexOf('.') + 1);
      const suffix = img === 'jpg' || img === 'png' || img === 'jpeg';
      if (!suffix) {
        this.$message.error('只能上传图片');
        return false;
      }
      const reader = new FileReader();
      reader.onload = () => {
        this.file =
          typeof reader.result === 'object'
            ? window.URL.createObjectURL(new Blob([reader.result]))
            : reader.result;
      };
      this.cropperVisible = true;
      reader.readAsArrayBuffer(file.raw);
      this.fileName = file.name;
    },
    async onConfirm(blob) {
      const form = new FormData();
      form.append('file', blob);
      this.$http({
        url: `/cc/oss/image/upload`,
        method: 'post',
        data: form,
      }).then(({ data }) => {
        if (data && data.status === 0) {
          this.dataForm.imageUrl = data.data.imageUrl;
          this.cropperVisible = false;
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.dataForm.freezerIds = this.dataForm.freezerIds.join(',');
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/cc/freezer-banner/save`,
            method: 'post',
            data: this.dataForm,
          }).then(({ data }) => {
            if (data && data.status === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('refreshDataList');
            }
          });
        }
      });
    },

    //智能取餐柜列表
    getFreezerList() {
      getFreezerList(1).then(({ data }) => {
        if (data && data.status === 0) {
          this.freezerList = data.data.items;
        }
      });
    },
    // 删除图片，假删除
    deleteHandle(id) {
      this.dataForm.fileList = this.dataForm.fileList.filter(
        (i) => i.id !== id,
      );
    },
    openDialog() {
      this.picVisible = true;
      this.$nextTick(() => {
        this.$refs.picdialog.init(this.dataForm.fileList);
      });
    },
    getInsidePic(row) {
      this.files = this.dataForm.fileList;
      this.dataForm.fileList = Array.from(new Set(row));
      this.dataForm.fileList.forEach((item) => {
        if (item.id) {
          this.files.forEach((i) => {
            if (i.id === item.id) {
              item.name = i.name;
            }
          });
        }
      });
    },
  },
};
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  border-radius: 0 !important;
  display: block;
}
</style>
